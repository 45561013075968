@import '../../styles/customMediaQueries.css';


.pageRoot {
  padding-bottom: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;

  @media (--viewportLarge) {
    padding-bottom: 0;
    margin: 0;
    width: 100%;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  position: absolute;
  display: flex;
  top: 0px;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
  @media (--viewportMaxSmallDevice) {
    position: relative;
    top: 59px;
    width: 100%;
  }
}
.actionBarParent {
  position: relative;
}
.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}
.cityCountry {
  font-weight: 400;
  font-family: Noto Sans JP;
  color: #1c7881;
  font-size: 20px;
  line-height: 26px;
}
.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  margin: 14px 12px 11px 24px;
  font-size: 130%;
  color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;
  color: white;
  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  flex-shrink: 0;

  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  z-index: 999;
  @media (--viewportLarge) {
    display: block;
    margin-top: 2px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
  @media screen and (max-width: 750px) {
    position: sticky;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}
.leftRightContainer {
  display: flex;
  height: auto;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}
.containerLeft {
  width: 100%;
  @media (--viewportLarge) {
    width: 65%;
  }
}
.containerRight {
  width: 30vw;
  margin-left: 1em;
  position: sticky;
  /* margin-top: 3em; */
  top: 5em;
  /* max-height: 520px; */
  height: 100%;
  scroll-behavior: smooth;
  /* padding-bottom: 50px; */
  z-index: 10; 
}

.whatsappLinkRef {
  color: white;
}
.sectionMap {
  padding: 0 1em;
  margin-bottom: 35px;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}
.sectionMap > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
  margin: 1em 0 0.5em 0;
}
.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.onSiteHowtoGet {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 500px);
  width: 100%;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;

  pointer-events: visible;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 65vh;
  }

  @media (--viewportLarge) {
    height: 334px;
    width: 100%;
    margin: 0 auto;
  }
}

.reviewsContainer {
  margin: 2em 1em;
  z-index: 1 !important;
  display: flex;
  @media (--viewportMedium) {
    margin: 1em 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

/* Migration css to the new repo */
.contentContainer {
  clear: both;
  margin: 0;
  width: 95vw;
  margin: 0 auto;
  @media screen and (--viewportMedium) {
    max-width: 1300px;
  }
  @media screen and (--viewportXLarge) {
    max-width: 1400px;
  }
}

.topContent {
  flex-basis: 100%;
  margin-bottom: 23px;
  max-width: 100%;
  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    flex-shrink: 0;
    flex-grow: 1;
  }
  @media (--viewportMaxSmallDevice) {
    max-width: 82%;
  }
}
.topContent1 {
  flex-basis: 100%;
  margin-bottom: 23px;
  max-width: 100%;
  margin-top: -20px;
  @media (--viewportMedium) {
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}
.bottomContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: baseline;
  margin-top: 1em;
  width: 100%;
}
@keyframes startAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.mapContainer {
  margin-top: 3em;
  position: relative;
  z-index: -1;
}
.bookingRowWrapper {
  display: grid;
  grid-template-rows: 1fr;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}
.accomodationDescWrapper {
  position: relative;
  z-index: 9;
}

.leftSection {
  grid-column: span 2;
}

.rightSection {
  grid-column: span 1;
}
.favButton {
  padding: 8px 0 0 0;
  pointer-events: none;
  border: none;
}
.favButtonContainer {
  pointer-events: none;
  height: 0px;
  position: relative;
  text-align: right;
  top: 45px;
  @media screen and (max-width: 1050px) {
    /*  right: -15px; */
  }
  @media screen and (max-width: 999px) {
    /*     top: 0px; */
    right: 0px;
  }
}
.favImg {
  width: 35px;
  pointer-events: all;

  &:hover {
    scale: 1.1;
  }
  &:active {
    scale: 0.95;
  }
}
.favButtonContainerChild {
  padding: 0;
  border: none;
}

.whatsappWrapper {
  position: relative;
  text-align: center;
  padding-top: 2em;
  @media screen and (min-width: 1228px) {
    padding: 0;
  }

}

.whatsAppLink {
  background-color: #25d366;
  width: 35%;
  height: 45px;
  position: absolute;
  bottom: -85px;
  left: 12%;
  border: 5px solid transparent;
  border-radius: 35px;
  display: none;
  @media screen and (--viewportLarge) {
    display: block;
  }
  &:hover{
    scale: 1.02;
  }
  &:active{
    scale: 0.97;
  }
}
.whatsappContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  cursor: pointer;
}
.whatsappIcon {
  width: 52px;
  height: 45px;
  background-color: #25d366;
  color: #25d366;
  fill: #25d366;
  margin-left: -25px;
  margin-right: 0px;
  border: 5px solid transparent;
  border-radius: 35px;
}

.whatsappSentence {
  display: none;
  font-size: small;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  bottom: -2.5em;

  @media (--viewportLarge) {
    display: block;
  }
}

.EmailLink {
  background-color: #1c7881;
  width: 35%;
  height: 45px;
  position: absolute;
  bottom: -85px;
  left: 52%;
  border: 5px solid transparent;
  border-radius: 35px;
  display: none;
  @media screen and (--viewportLarge) {
    display: block;
  }
  &:hover{
    scale: 1.02;
  }
  &:active{
    scale: 0.97;
  }
}
.emailText{
  color: white;
}

.emailContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  cursor: pointer;
}
.emailIcon {
  width: 42px;
  height: 40px;
  background-color: #1c7881;
  color: #1c7881;
  fill: #1c7881;
  margin-left: -25px;
  margin-right: 0px;
  border: 5px solid transparent;
  border-radius: 35px;
}

.faqWrapper {
  width: 100%;
  display: flex;
  align-items:flex-start;
  flex-direction: column;
}

.highPriorityOrderPanel {
  z-index: 999;
}

.reviewRatingsClass{
  display: flex;
}
